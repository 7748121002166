import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import withOrderContext from '../../../withOrderContext';
import withCustomerContext from '../../../withCustomerContext';

import {
  colors, mobileThresholdPixels,
  StyledTitle2 as Title2,
} from '../../home/v3/styledComponents';
import PasswordForm from '../PasswordForm';
import logoMagnifyingGlass from '../../../assets/magnifyingGlass.png';

const Container = styled.div`
  width: 100%;
  @media (max-width: ${mobileThresholdPixels}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 7px;
    width: auto;
  }
`;

const ComponentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${colors.navy};
  font-family: Roboto;
  margin-top: 80px;
  background-color: ${colors.white};
  border-top: solid 8px ${colors.yellow};
  box-shadow: 0px 1px 6px ${colors.blueGrey};
  padding: 52px 142px 61px;
  @media (max-width: ${mobileThresholdPixels}) {
    padding: 25px 20px 30px;
    margin-top: 34px;
  }
`;

const ComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16.5px;
  width: 100%;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: 20px;
  }
`;

const ComponentHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => props.column && 'flex-direction: column; margin-top: 70.5px;'}
  ${(props) => props.marginBottom && `margin-bottom: ${props.marginBottom}px;`}
  font-family: Libre Baskerville;
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  text-align: center;
  @media (max-width: ${mobileThresholdPixels}) {
    align-items: flex-start;
    ${(props) => props.column && 'margin-top: 30px; align-items: center;'}
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 30px;
  }
`;

const Img = styled.img`
  margin: 0px 20px 0px -31px;
  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px 2px 0px 0px;
  }
`;

const EmptyDiv = styled.div`
  margin-left: 31px;
  height: 31px;
`;

const P = styled.p`
  font-size: 18px;
  line-height: 28px;
  margin: 0px 0px 23px;
  ${(props) => props.bold && 'font-weight: 500;'}
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    line-height: 20px;
    margin: 0px 0px 10px;
  }
`;

const Line = styled.div`
  position: relative;
  top: -1px;
  flex: 1;
  height: 1px;
  width: 692px;
  background-color:  ${colors.lightGrey2};
  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
  }
`;

class MasksSuccessPage extends React.Component {
  componentDidMount() {
    if (typeof window === 'undefined') return;
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    const { orderContext: { initialize } } = this.props;
    initialize();
  }

  render() {
    const { customerContext } = this.props;
    const { customer = {} } = customerContext;
    const { isShadowAccount } = customer;
    return (
      <Container>
        <Title2>Merci d’avoir commandé !</Title2>
        <ComponentsContainer>
          <ComponentContainer>
            <ComponentHeader marginBottom={40}>
              <Img src={logoMagnifyingGlass} alt="À la recherche du meilleur couturier" />
              <div>Confirmation de commande</div>
              <EmptyDiv />
            </ComponentHeader>
            <P>
              Nous avons bien reçu votre commande.
              Les masques sont en cours de fabrication.
              Nous vous tiendrons au courant par mail de leur expédition.
            </P>
            <P>À très vite ! :)</P>
            <P>L’équipe Tilli</P>
          </ComponentContainer>
          {isShadowAccount && (
            <>
              <Line />
              <PasswordForm />
            </>
          )}
        </ComponentsContainer>
      </Container>
    );
  }
}

MasksSuccessPage.propTypes = {
  orderContext: PropTypes.shape({
    slots: PropTypes.shape({}),
    initialize: PropTypes.func,
  }).isRequired,
  customerContext: PropTypes.shape({
    customer: PropTypes.shape({
      isShadowAccount: PropTypes.bool,
    }),
  }).isRequired,
};

export default withCustomerContext(withOrderContext(MasksSuccessPage));
